import React, { lazy } from 'react';

const LUFP_V1 = lazy(() => import('./Components/Pages/Splits/LUFP_V1'))

const AppSplitList = (props) => {

    const SplitListMap = {
        'LUFP_V1': LUFP_V1
    }
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;