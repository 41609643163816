import { Helmet } from "react-helmet";
import faviconLookup from "../../../../assets/img/LUFP_V1/favicon.ico";
import faviconScanandClaim from "../../../../assets/img/LUFP_V1/scan-claim-favicon.png";

const HelmetBasedOnDomain = () => {
    const domainName = window.location.hostname;
    let favicon;
    let brandName;
    if (domainName === 'localhost' || domainName === 'dev.lookupfinance.co.uk' 
        || domainName === 'pre.lookupfinance.co.uk' || domainName === 'lookupfinance.co.uk') {
        favicon = faviconLookup;
        brandName = 'LookUp Finance';
    }else if(domainName === 'dev.scanandclaim.co.uk' 
        || domainName === 'pre.scanandclaim.co.uk' || domainName === 'scanandclaim.co.uk'){
        favicon = faviconScanandClaim;
        brandName = 'Scan & Claim';
    }else {
        favicon = faviconLookup;
        brandName ="LookUp Finance"
    }
    return (
        <Helmet>
            <link rel="icon" href={favicon} />
            <title>{brandName}</title>
        </Helmet>
    )
}

export default HelmetBasedOnDomain;