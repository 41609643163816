import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppApiUrl } from "../../../Constants/EnvConstants";

const ctrlShiftKey = (e, keyCode) => {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
};

const EnvDocView = () => {
  const { doc, docType, filename } = useParams();
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token") || '';
    const lender_id = query.get("lender_id") || '';

    if (!docType || !filename) return;
    console.log(docType);
    let url = AppApiUrl.replace(/\/$/, "");


    const apiRoute = `${url}/${doc}/${docType}/${filename}`;
    console.log(apiRoute);
    setFileUrl(apiRoute);
  }, [docType, filename]);

  useEffect(() => {
    // Disable right-click
    document.addEventListener("contextmenu", (e) => e.preventDefault());

    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
      )
        return false;
    };
  }, []);

  if (!fileUrl) return <></>;

  return (
    <>
      <object
        width="100%"
        height="800"
        data={fileUrl}
        type="application/pdf"
        aria-label="Doc preview"
      ></object>
    </>
  );
};

export default EnvDocView;
